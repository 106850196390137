import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['stars.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class StarsElement {
    @Input() ratingValue: number = 0;

    mathTrunc(value) {
        return Math.trunc(value);
    }
}
